.welcome {
  padding-bottom: 83px;
  position: relative;
  .welcome-inner {
    height: calc(100vh - 83px - #{$header-height});
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .left {
      width: 66%;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .right {
      width: 34%;
      margin-left: auto;
      h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
      }
      h3 {
        margin-top: 39px;
        font-weight: 400;
        font-size: 16px;
      }
      .buttons-wrapper {
        margin-top: 49px;
        display: flex;
        align-items: center;
        gap: 20px;
        a {
          display: contents;
        }
      }
    }
  }
  &.club-games {
    .welcome-inner {
      justify-content: flex-start;
    }
    .left {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      img {
        width: 100%;
        max-height: 100%;
      }
    }
    .right {
      margin-left: 0;
    }
  }
  &.gameBox {
    .welcome-inner {
      .left {
        img {
          max-height: unset;
          max-width: unset;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1095px) {
  .welcome:not(.club-games):not(.mobile) {
    .welcome-inner {
      flex-direction: column;
      height: 100%;
      padding-top: 20px;
      .left {
        width: 90%;
      }
      .right {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
@media screen and (max-width: 1095px) {
  .welcome.club-games:not(.mobile) {
    .welcome-inner {
      flex-direction: column;
      .left {
        width: 100%;
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right {
        width: 100%;
      }
    }
  }
}

.welcome.mobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 54px;
  .welcome-image-wrapper {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .welcome-inner {
    height: auto;
    justify-content: flex-start;
    gap: 0;
    .right {
      margin-left: 0;
      width: 85%;
      h2 {
        font-size: 26px;
        line-height: 33px;
      }
      h3 {
        margin-top: 12px;
        font-size: 12px;
        line-height: 15px;
      }
      .buttons-wrapper {
        margin-top: 20px;
        gap: 8.5px;
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .welcome.mobile {
    .welcome-inner {
      justify-content: center;
    }
  }
}