@font-face {
  font-family: 'CloudSideTech';
  src:  url('fonts/CloudSideTech.eot?e6l6a6');
  src:  url('fonts/CloudSideTech.eot?e6l6a6#iefix') format('embedded-opentype'),
    url('fonts/CloudSideTech.ttf?e6l6a6') format('truetype'),
    url('fonts/CloudSideTech.woff?e6l6a6') format('woff'),
    url('fonts/CloudSideTech.svg?e6l6a6#CloudSideTech') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CloudSideTech' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info_FILL0_wght400_GRAD0_opsz48:before {
  content: "\e92d";
}
.icon-channel:before {
  content: "\e900";
}
.icon-signout:before {
  content: "\e901";
}
.icon-comment-alt-exclamation:before {
  content: "\e902";
}
.icon-home:before {
  content: "\e903";
}
.icon-games:before {
  content: "\e904";
}
.icon-lock:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-plus-square:before {
  content: "\e907";
}
.icon-finance:before {
  content: "\e908";
}
.icon-user-circle:before {
  content: "\e909";
}
.icon-user-square:before {
  content: "\e90a";
}
.icon-wallet:before {
  content: "\e90b";
}
.icon-menu-open:before {
  content: "\e90c";
}
.icon-menu-closed:before {
  content: "\e90d";
}
.icon-angle-right:before {
  content: "\e90e";
}
.icon-angle-left:before {
  content: "\e90f";
}
.icon-angle-up:before {
  content: "\e910";
}
.icon-angle-down:before {
  content: "\e911";
}
.icon-reports:before {
  content: "\e912";
}
.icon-clipboard-notes:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-info:before {
  content: "\e915";
}
.icon-business_config:before {
  content: "\e916";
}
.icon-close:before {
  content: "\e917";
}
.icon-stop:before {
  content: "\e918";
}
.icon-play_icon:before {
  content: "\e919";
}
.icon-configs:before {
  content: "\e91a";
}
.icon-game:before {
  content: "\e91b";
}
.icon-tournament:before {
  content: "\e91c";
}
.icon-table:before {
  content: "\e91d";
}
.icon-time:before {
  content: "\e91e";
}
.icon-cross:before {
  content: "\e91f";
}
.icon-visibility:before {
  content: "\e920";
}
.icon-kick:before {
  content: "\e921";
}
.icon-windows:before {
  content: "\e922";
}
.icon-android:before {
  content: "\e923";
}
.icon-apple:before {
  content: "\e924";
}
.icon-desktop:before {
  content: "\e925";
}
.icon-mobile-android:before {
  content: "\e926";
}
.icon-globe:before {
  content: "\e927";
}
.icon-users:before {
  content: "\e928";
}
.icon-package:before {
  content: "\e929";
}
.icon-club-app:before {
  content: "\e92a";
}
.icon-network:before {
  content: "\e92b";
}
.icon-poker:before {
  content: "\e92c";
}
