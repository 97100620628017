@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");
@import "../../assets/icons/style";
@import "./reset.scss";
@import "./variables.scss";
@import "./overWrite.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $light;
  background-color: $dark-gray;
}

.container {
  max-width: $layout-with;
  padding: 0 15px;
  margin: 0 auto;
}

.btn {
  max-width: 174px;
  width: 100%;
  height: 40px;
  font-weight: 700;
  color: $primary;
  border: 2px solid $primary;
  border-radius: 20px;
  transition: 0.325s ease-in-out;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  &:hover {
    background-color: $primary;
    color: $dark-3;
  }
  &.primary {
    background-color: $primary;
    color: $dark-3;
    &:hover {
      background-color: lighten($primary, 8%);
      border-color: lighten($primary, 8%);
    }
  }
  &.disabled,
  &:disabled {
    background-color: #ced0e6;
    color: #757789;
    border-color: #ced0e6;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: #ced0e6;
      color: #757789;
      border-color: #ced0e6;
    }
  }
}