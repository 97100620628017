.features {
  padding: 60px 0;
  .features-inner {
    display: flex;
    justify-content: center;
    gap: 16px;
    .left {
      padding-top: 40px;
    }
    h3 {
      font-size: 56px;
      font-weight: 700;
      width: 400px;
      text-align: right;
    }
    .cards-wrapper{
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .cards {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .card {
      border-radius: 23px;
      padding: 30px;
      color: $dark-4;
      background-color: $light-2;
      box-shadow: 0 12px 104px 0 #A1A7D8;
      background-position: 130% 130%;
      background-repeat: no-repeat;
      position: relative;
      &.betYourHand {
        background-image: url('../../assets/images/features/betYourHand.png');
      }
      &.colorfulThemes {
        background-image: url('../../assets/images/features/colorfulThemes.png');
      }
      &.insurance {
        background-image: url('../../assets/images/features/insurance.png');
      }
      &.combinationToolOption {
        background-image: url('../../assets/images/features/combinationToolOption.png');
      }
      &.callTime {
        background-image: url('../../assets/images/features/callTime.png');
      }
      &.right {
        margin-left: auto;
      }
      &.large {
        width: 408px;
        height: 408px;
      }
      &.medium {
        width: 329px;
        height: 329px;
      }
      &.small {
        width: 270px;
        height: 270px;
      }
      h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: inherit;
      }
      p {
        margin-top: 10px;
      }
      i {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1095px) {
  .features:not(.mobile) {
    .features-inner{
      flex-direction: column;
      .cards-wrapper {
        margin-top: 60px;
        align-items: center;
      }
      .left {
        padding-top: 0;
      }
      .right {
        .cards-wrapper {
          margin-top: 0;
        }
      }
      h3 {
        width: 100%;
        text-align: center;
      }
      .cards {
        flex-direction: column;
        align-items: center;
      }
      .card {
        &.right {
          margin-left: 0;
        }
        &.large, &.medium, &.small {
          width: 100%;
          height: 329px;
        }
        .info-title-wrapper {
          display: flex;
          align-items: center;
          gap: 6px;
        }
        i {
          position: static;
        }
      }
    } 
  }
}

.features{
  &.mobile {
    padding: 54px 0 0 0;
    .features-inner{
      flex-direction: column;
      gap: 0;
      .cards-wrapper {
        margin-top: 16px;
        align-items: center;
        gap: 0;
      }
      .left {
        padding-top: 0;
      }
      .right {
        .cards-wrapper {
          margin-top: 0;
        }
      }
      .left, .right{
        .cards-wrapper {
          .card {
            border-radius: 0 0 23px 23px;
          }
        }
      }
      h3 {
        width: 100%;
        text-align: center;
        font-size: 22px;
        line-height: 28px;
      }
      .cards {
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
      .card {
        &.right {
          margin-left: 0;
        }
        &.large, &.medium, &.small {
          width: 100%;
        }
        h4 {
          font-size: 16px;
          line-height: 20px;
        }
        p {
          font-size: 12px;
          line-height: 15px;
          margin-top: 18px;
        }
        .info-title-wrapper {
          display: flex;
          align-items: center;
          gap: 6px;
        }
        i {
          position: static;
        }
      }
      > .left, > .right {
        position: relative;
        .card {
          height: fit-content;
          padding: 30px 20px 20px 20px;
          background-image: unset;
          position: relative;
          overflow: hidden;
          &:before {
            content: '';
            position: absolute;
            width: 50%;
            height: 50%;
            right: 0;
            bottom: 0;
            background-position: center;
            background-size: cover;
            z-index: 100;
            pointer-events: none;
          }
          &.betYourHand {
            &:before {
              background-image: url('../../assets/images/features/betYourHand.png');
              width: 60%;
              height: 100%;
              right: -35px;
              bottom: -32px;
            }
          }
          &.colorfulThemes {
            &:before {
              background-image: url('../../assets/images/features/colorfulThemes.png');
              width: 22%;
              height: 53%;
              right: -10px;
              bottom: -14px;
            }
          }
          &.insurance {
            &:before {
              background-image: url('../../assets/images/features/insurance.png');
            }
          }
          &.combinationToolOption {
            &:before {
              background-image: url('../../assets/images/features/combinationToolOption.png');
            }
          }
          &.callTime {
            &:before {
              background-image: url('../../assets/images/features/callTime.png');
            }
          }
        }
      }
      > .left {
        z-index: 10;
        .cards-wrapper {
          > .card, >.cards {
            position: relative;
          }
          > .card {
            &:first-child {
              z-index: 10;
              height: 191px;
              padding: 20px;
              border-radius: 23px;
            }
            &:last-child {
              z-index: 8;
              transform: translateY(-60px);
            }
          }
          > .cards {
            z-index: 9;
            transform: translateY(-20px);
            > .card {
              position: relative;
              &:first-child {
                z-index: 10;
              }
              &:last-child {
                z-index: 8;
                transform: translateY(-20px);
              }
            }
          }
        }
      }
      > .right {
        transform: translateY(-80px);
        z-index: 9;
        .cards-wrapper {
           > * {
            position: relative;
            &:first-child {
              z-index: 10;
            }
            &:not(:first-child) {
              z-index: 9;
              transform: translateY(-20px);
            }
          }
          .card:last-child {
            height: 191px;
          }
        }
      }
    } 
  }
}