.club-games-options {
  padding: 60px 0;
  .club-games-options-inner {
    h3 {
      font-weight: 700;
      font-size: 50px;
      text-align: center;
    }
    .club-games-list {
      display: flex;
      align-items: center;
      gap: 21px;
      margin-top: 148px;
    }
    .club-games-item {
      width: calc(33.3% - 21px);
      height: 483px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px 122px;
      img {
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
      }
      h4 {
        font-size: 50px;
        font-weight: 700;
        text-align: center;
        line-height: 1.2;
      }
    }
  }
}

@media screen and (max-width: 1095px) {
  .club-games-options:not(.mobile) {
    .club-games-options-inner {
      .club-games-list {
        flex-direction: column;
        .club-games-item {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-start;
          gap: 15px;
          padding: 20px;
          height: auto;
          img {
            position: static;
            transform: translateX(0);
          }
          h4 {
            margin-left: auto;
          }
        }
      }
    } 
  } 
}
@media screen and (max-width: 740px) {
  .club-games-options:not(.mobile) {
    .club-games-options-inner {
      .club-games-list {
        .club-games-item {
          flex-direction: column;
          h4 {
            margin-left: 0;
          }
        }
      }
    } 
  } 
}

.club-games-options.mobile {
  padding: 54px 0 0 0;
  .club-games-options-inner {
    h3 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .club-games-item {
      width: auto;
      h4 {
        font-size: 30px;
        line-height: 38px;
      }
      img {
        position: static;
        transform: translate(0);
      }
    }
  }
}