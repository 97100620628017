.game-box {
  padding: 34px 0;
  &__container {
    max-width: 1320px;
    padding: 0 15px;
    margin: 0 auto;
  }
  &__backgorund-block {
    border-radius: 16px;
    padding: 0 65px 0 60px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 518px;
    &--first {
      .game-box__backgorund-block-text-description {
        margin-top: 20px;
      }
      .game-box__arrow-image {
        position: absolute;
        top: 90px;
        right: 95%;
        @media screen and (max-width: 1136px) {
          display: none;
        }
      }
      .game-box__image-1 {
        @media screen and (max-width: 1136px) {
          width: 50%;
          aspect-ratio: 351/527;
        }
      }
      .game-box__image-2 {
        @media screen and (max-width: 1136px) {
          width: 50%;
          aspect-ratio: 277/502;
        }
      }
    }
    &--last {
      margin-top: 56px;
      align-items: stretch;
      .game-box__backgorund-block-texts-wrapper {
        justify-content: center;
      }
      .game-box__backgorund-block-text-subtitle {
        margin-top: 34px;
      }
      .game-box__backgorund-block-text-description {
        margin-top: 16px;
      }
      .game-box__images-wrapper {
        position: relative;
        flex-grow: 1;
        display: flex;
        @media screen and (max-width: 1130px) {
          margin-top: 20px;
        }
      }
      .game-box__arrow-image {
        position: absolute;
        top: 135px;
        left: 283px;
        @media screen and (max-width: 1136px) {
          display: none;
        }
      }
      .game-box__image-1 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 283px;
        @media screen and (max-width: 1136px) {
          position: static;
          aspect-ratio: 64/81;
          width: 50%;
        }
      }
      .game-box__image-2 {
        position: absolute;
        right: 0;
        bottom: 10px;
        width: 259px;
        @media screen and (max-width: 1136px) {
          position: static;
          aspect-ratio: 15/18;
          width: 50%;
        }
      }
    }
    @media screen and (max-width: 1130px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 15px 0;
    }
  }
  &__backgorund-block-texts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 40%;
    @media screen and (max-width: 1130px) {
      align-items: center;
      max-width: 90%;
    }
  }
  &__backgorund-block-text-title {
    font-weight: 700;
    font-size: 46px;
    line-height: 58px;
    color: #F0F0E6;
    @media screen and (max-width: 1130px) {
      text-align: center;
    }
    @media screen and (max-width: 500px) {
      font-size: 38px;
      line-height: 50px;
    }
  }
  &__backgorund-block-text-subtitle {
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #F0F0E6;
    @media screen and (max-width: 1130px) {
      text-align: center;
    }
    @media screen and (max-width: 500px) {
      font-size: 23px;
      line-height: 30px;
    }
  }
  &__backgorund-block-text-description {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #BBBBBD;
    @media screen and (max-width: 1130px) {
      text-align: center;
    }
    @media screen and (max-width: 500px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__images-wrapper {
    position: relative;
    margin-left: auto;
    display: flex;
    @media screen and (max-width: 1130px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  &__block{
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__block-title{
    max-width: 86%;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #F0F0E6;
    @media screen and (max-width: 500px) {
      font-size: 31px;
      line-height: 40px;
    }
  }
  &__block-subtitle{
    margin-top: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #BBBBBD;
    @media screen and (max-width: 500px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__block-phones{
    margin-top: 36px;
    width: 70%;
    aspect-ratio: 405/229;
  }
}

