.navigation-mobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 56vw;
  height: 100%;
  z-index: 10;
  .navigation-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $dark-4;
    color: $light;
    padding: 20px;
    a {
      height: 50px;
      transition: 0.35s ease-in-out;
      font-size: 20px;
      display: flex;
      align-items: center;
      &.active {
        color: $primary;
      }
      & + a {
        border-top: 1px solid $light;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}

