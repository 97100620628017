.header {
  background-color: $dark-3;
  .header-inner {
    height: $header-height;
    align-items: center;
    display: flex;
  }
  .right {
    flex-grow: 0.5;
    display: flex;
    align-items: center;
    margin-left: auto;
    a {
      display: contents;
    }
  }
  .icon-menu-closed {
    margin-left: auto;
    font-size: 20px;
    color: $light;
    cursor: pointer;
  }
}