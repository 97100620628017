.dropdown {
  position: relative;
  .drop-item {
    display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
  }
  &.open {
    .header {
      border-radius: 8px 8px 0 0;
      border-bottom: none;
      i {
        transform: rotate(180deg);
      }
    }
    .body {
      pointer-events: all;
      opacity: 1;
      .drop-item {
        padding: 4px;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    color:$purpule;
    font-weight: 700;
    gap: 5.5px;
    cursor: pointer;
    .drop-item {
      margin: 0;
    }
    i {
      margin-left: auto;
      color: $purpule;
      transition: color 0.35s ease-in-out;
      transform: translateY(2px);
      font-size: 10px;
    }
  }
  .body {
    position: absolute;
    top: 125%;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    background: $dark-4;
    color: $purpule;
    padding: 2px;
    padding: 0;
    border-radius: 0 0 8px 8px;
    z-index: 2;
    .drop-item {
      margin-left: 0;
      padding: 0;
      cursor: pointer;
    }
  }
}
