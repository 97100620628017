.byh {
  padding: 60px 0;
  .row {
    margin-bottom: 40px;
  }
  h3,
  h4 {
    color: $red;
    font-weight: 700;
    & + p {
      margin-top: 10px;
    }
  }
  p.center {
    text-align: center;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 20px;
  }
  .byh-inner {
    .img-title-wrapper {
      max-width: 70vmax;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .combination-wrapper {
      background-color: $light-2;
      display: flex;
      gap: 20px;
      padding: 40px 20px;
      span,
      i,
      p {
        color: $dark;
      }
      .item {
        width: calc(50% - 20px);
      }
      .combination-header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-bottom: 4px solid $dark;
        padding-bottom: 10px;
      }
      .header_texts {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .combination-body {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        .combination-item {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          div {
            width: calc(40% - 10px);
            display: flex;
            flex-direction: column;
            gap: 2px;
            .small {
              font-size: 12px;
            }
          }
          h4 {
            width: calc(30% - 10px);
            text-align: center;
          }
        }
      }
    }
    .cost-participate-wrapper {
      background-color: $light-2;
      padding: 40px 20px;
      span,
      strong {
        color: $dark;
      }
      strong.red {
        color: $red;
      }
      .item {
        width: 30%;
      }
      .cost-participate-header, .cost-participate-item {
        display: flex;
        gap: 20px;
        i {
          display: contents;
        }
        span {
          width: calc(50% - 20px);
        }
        div {
          width: calc(50% - 20px);
          display: flex;
          gap: 10px;
          text-align: center;
          span, strong {
            width: 33.3%;
          }
        }
      }
      .cost-participate-header {
        border-bottom: 4px solid $dark;
        padding-bottom: 10px;
      }
      .cost-participate-body {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
    .terms-conditions-wrapper, .rules-wrapper {
      padding: 0 15px;
      ul {
        margin-top: 20px;
        list-style: none;
        li {
          &::before {
            content: "\2022";
            color: $red;
            font-weight: bold;
            display: inline-block; 
            width: 1em;
            margin-left: -1em;
          }
          & + li {
            margin-top: 6px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .byh .byh-inner {
    .combination-wrapper {
      flex-direction: column;
      .item {
        width: 100%;
      }
    }
    .cost-participate-wrapper {
      .item {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .byh .byh-inner {
    .combination-wrapper {
      .header_texts {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}
