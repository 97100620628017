.navigation {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0.5;
  height: 100%;
  .navigation-list {
    display: flex;
    align-items: center;
    a {
      padding: 25px;
      color: $purpule;
      position: relative;
      &:hover {
        color: $primary;
      }
      &.active {
        color: $primary;
        background-color: $dark-2;
        font-weight: 700;
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 29px;
          height: 9px;
          transform: translateX(-50%);
          background-color: $primary;
          border-radius: 6px 6px 0 0;
        }
      }
    }
  }
}