// colors
$dark: #1B1C28;
$dark-2: #1D1E29;
$dark-3: #22242F;
$dark-4: #292B38;

$primary: #0F9086;
$purpule: #7B87D4;

$dark-gray: #393B4C;
$dark-gray-2: #4A4D62;
$dark-gray-3: #4C4D5D;

$blue: #1E2034;

$light: #ffffff;
$light-2: #E3E4F8;

$red: #dc3545;


// main
$layout-with: 1440px;

// logo

$logo-height: 40px;

// header
$header-height: 70px;

